document.addEventListener("DOMContentLoaded", function () {
  const form = document.querySelector(".needs-validation");

  // フォームが存在しない場合は処理を中断
  if (!form) return;

  form.addEventListener("submit", function (event) {
    let isValid = true;

    // 各質問ごとにラジオボタンの選択チェック
    document.querySelectorAll(".question-group").forEach((group) => {
      const radioButtons = group.querySelectorAll("input[type='radio']");
      const errorMessage = group.querySelector(".invalid-feedback");

      if (![...radioButtons].some((radio) => radio.checked)) {
        radioButtons.forEach((radio) => radio.classList.add("is-invalid"));
        if (errorMessage) errorMessage.style.display = "block";
        isValid = false;
      } else {
        radioButtons.forEach((radio) => radio.classList.remove("is-invalid"));
        if (errorMessage) errorMessage.style.display = "none";
      }
    });

    if (!isValid) {
      event.preventDefault();
      event.stopPropagation();
    }

    form.classList.add("was-validated");
  });

  // ラジオボタンの選択時にエラーメッセージを消す
  document.querySelectorAll(".question-group input[type='radio']").forEach((radio) => {
    radio.addEventListener("change", function () {
      const group = this.closest(".question-group");
      group.querySelectorAll("input").forEach((r) => r.classList.remove("is-invalid"));
      group.querySelector(".invalid-feedback").style.display = "none";
    });
  });
});
